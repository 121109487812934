import React, { useEffect, useMemo, useState } from 'react';
import { CircuitTreeLeafInfo } from './Tree';
import Picker, { DataType, QueryParams } from './Picker';
import dayjs from 'dayjs';
import { CircuitData, getCircuitData } from '../../api/circuit';
import { isNil } from 'lodash-es';
import { Spin } from 'antd';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import { FormatterItem } from '../Statistics/components/Chart/helper';
import { Empty } from '@maxtropy/components';

export interface CompositionProps {
  circuit: CircuitTreeLeafInfo;
}

const Composition: React.FC<CompositionProps> = ({ circuit }) => {
  const [query, setQuery] = useState({
    type: 2,
    dataType: DataType.HOUR,
    from: dayjs().startOf('day').valueOf(),
    to: dayjs().endOf('day').valueOf(),
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CircuitData[]>();

  const isNotEmpty = useMemo(() => {
    return data && data.some(item => item.data.some(o => !isNil(o.value)));
  }, [data]);

  useEffect(() => {
    setLoading(true);
    getCircuitData({
      circuitId: circuit.circuitId,
      ...query,
    })
      .then(setData)
      .finally(() => setLoading(false));
  }, [circuit, query]);

  return (
    <div>
      <Picker
        start={circuit.createTime}
        onChange={v => setQuery({ ...query, ...v })}
        dataType={query.dataType}
        from={query.from}
        to={query.to}
      />
      <Spin spinning={loading}>
        {isNotEmpty || loading ? (
          <div className={styles.chartContainer}>
            {isNotEmpty ? (
              <ReactEcharts theme={'dark'} option={getChartOption(query, data!)} notMerge />
            ) : (
              <div style={{ height: 200 }} />
            )}
          </div>
        ) : (
          <Empty
            imageStyle={{ height: 60 }}
            description={'暂无数据'}
            style={{ padding: '70px 0', margin: '38px 0', background: 'rgba(255, 255, 255, 0.05)' }}
          />
        )}
      </Spin>
    </div>
  );
};

const getChartOption = ({ dataType, from, to }: QueryParams, chartData: CircuitData[]) => {
  let data: number[] = [];
  if (dataType === DataType.HOUR) {
    data = Array.from({ length: (to - from) / (3600 * 1000) + 1 }, (_, i) => from + 3600 * 1000 * i);
  } else if (dataType === DataType.DAY) {
    data = Array.from({ length: dayjs(to).diff(dayjs(from), 'day') + 1 }, (_, i) => from + 24 * 60 * 60 * 1000 * i);
  } else {
    data = Array.from(
      {
        length: (dayjs(to).year() - dayjs(from).year()) * 12 + 1 + dayjs(to).month() - dayjs(from).month(),
      },
      (_, i) => dayjs(from).add(i, 'month').valueOf()
    );
  }

  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 50,
      right: 50,
      top: 95,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 100,
        bottom: 15,
        height: 20,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        showDetail: false,
      },
      { type: 'inside' },
    ],
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      padding: [8, 12],
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 12,
      },
      formatter(items: FormatterItem[]) {
        const { axisValue } = items[0];
        const format =
          dataType === DataType.HOUR ? 'YYYY-MM-DD HH:mm' : dataType === DataType.DAY ? 'YYYY-MM-DD' : 'YYYY-MM';

        let str = `<div style="color:rgba(255,255,255,0.65);">${dayjs(axisValue, 'x').format(format)}</div>`;
        items
          .filter(item => item.seriesName !== '电网计费进线用电量')
          .forEach(item => {
            const { seriesName, data, marker } = item;
            const value = !isNil(data[1]) ? `${data[1]?.toFixed(2)}${'kWh'}` : '--';
            str += `<div style="width:280px;line-height:20px;margin-top:8px;display:flex;justify-content:space-between;"><div>${marker}${seriesName}</div><div>${value}</div></div>`;
          });
        str += `<div style="width:280px;line-height:20px;margin-top:8px;display:flex;justify-content:space-between;"><div>总电量</div><div> ${
          !isNil(items[0].data[1]) ? `${items[0].data[1]?.toFixed(2)}${'kWh'}` : '--'
        }</div></div>`;
        return str;
      },
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      type: 'category',
      data,
      axisLabel: {
        formatter(value: number) {
          const format =
            dataType === DataType.HOUR ? 'MM-DD[\n]HH:mm' : dataType === DataType.DAY ? 'MM-DD' : 'YYYY-MM';
          return dayjs(value, 'x').format(format);
        },
      },
    },
    yAxis: {
      type: 'value',
      name: 'kWh',
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
    legend: {
      show: true,
      right: 50,
      top: 50,
      itemGap: 18,
      itemWidth: 16,
      itemHeight: 7,
      data: [
        {
          name: '电网计费进线用电量',
          textStyle: {
            color: '#AFBCC4',
          },
          icon: 'rect',
        },
      ],
    },
    series: [
      {
        type: 'line',
        symbol: 'none',
        name: '电网计费进线用电量',
        data: chartData
          .find(item => !item.name)
          ?.data.map((a, index) => {
            return [index, a.value];
          }),
      },
      ...chartData
        .filter(item => !!item.name)
        .map(item => ({
          type: 'bar',
          name: item.name,
          stack: 'x',
          data: item.data.map((a, index) => {
            return [index, a.value];
          }),
        })),
    ],
  };
};

export default Composition;
