import React, { useEffect, useMemo, useState } from 'react';
import Picker, { DataType, QueryParams } from './Picker';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import { Spin } from 'antd';
import { isNil } from 'lodash-es';
import { CircuitData, getCircuitData } from '../../api/circuit';
import { CircuitTreeLeafInfo } from './Tree';
import { FormatterItem } from '../Statistics/components/Chart/helper';
import { Empty } from '@maxtropy/components';

export interface AnalysisProps {
  circuit: CircuitTreeLeafInfo;
}

const Analysis: React.FC<AnalysisProps> = ({ circuit }) => {
  const [query, setQuery] = useState({
    type: 1,
    dataType: DataType.HOUR,
    from: dayjs().startOf('day').valueOf(),
    to: dayjs().endOf('day').valueOf(),
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CircuitData[]>();

  const isNotEmpty = useMemo(() => {
    return data && data.some(item => item.data.some(o => !isNil(o.value)));
  }, [data]);

  useEffect(() => {
    setLoading(true);
    getCircuitData({
      circuitId: circuit.circuitId,
      ...query,
    })
      .then(setData)
      .finally(() => setLoading(false));
  }, [circuit, query]);

  return (
    <div>
      <Picker
        start={circuit.createTime}
        onChange={v => setQuery({ ...query, ...v })}
        dataType={query.dataType}
        from={query.from}
        to={query.to}
      />
      <Spin spinning={loading}>
        {isNotEmpty || loading ? (
          <div className={styles.chartContainer}>
            {isNotEmpty ? (
              <ReactEcharts theme={'dark'} option={getChartOption(query, data!)} notMerge />
            ) : (
              <div style={{ height: 200 }} />
            )}
          </div>
        ) : (
          <Empty
            imageStyle={{ height: 60 }}
            description={'暂无数据'}
            style={{ padding: '70px 0', margin: '38px 0', background: 'rgba(255, 255, 255, 0.05)' }}
          />
        )}
      </Spin>
    </div>
  );
};

const getChartOption = ({ dataType, from, to }: QueryParams, chartData: CircuitData[]) => {
  let data: number[] = [];
  if (dataType === DataType.HOUR) {
    data = Array.from({ length: (to - from) / (3600 * 1000) + 1 }, (_, i) => from + 3600 * 1000 * i);
  } else if (dataType === DataType.DAY) {
    data = Array.from({ length: dayjs(to).diff(dayjs(from), 'day') + 1 }, (_, i) => from + 24 * 60 * 60 * 1000 * i);
  } else {
    data = Array.from(
      {
        length: (dayjs(to).year() - dayjs(from).year()) * 12 + 1 + dayjs(to).month() - dayjs(from).month(),
      },
      (_, i) => dayjs(from).add(i, 'month').valueOf()
    );
  }

  const colors = ['#0050B3', '#00ADFF', '#63BC7F'];

  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 50,
      right: 80,
      top: 95,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 20,
        height: 20,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        showDetail: false,
      },
      { type: 'inside' },
    ],
    color: colors,
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      padding: [8, 12],
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 12,
      },
      formatter(items: FormatterItem[]) {
        const { axisValue } = items[0];
        const format =
          dataType === DataType.HOUR ? 'YYYY-MM-DD HH:mm' : dataType === DataType.DAY ? 'YYYY-MM-DD' : 'YYYY-MM';
        let str = `<div style="color:rgba(255,255,255,0.65);">${dayjs(axisValue, 'x').format(format)}</div>`;
        const n = (items[0].value as any)[1];
        items.forEach((item, i) => {
          const { seriesName, data, marker } = item;
          const value =
            !isNil(data[1]) && !isNil(n)
              ? `${seriesName === '电量' ? n.toFixed(2) : data[1].toFixed(2)}${seriesName === '电量' ? 'kWh' : '%'}`
              : '--';
          str += `<div style="width:200px;line-height:20px;margin-top:8px;display:flex;justify-content:space-between;"><div>${marker}${seriesName}</div><div>${value}</div></div>`;
        });
        return str;
      },
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      type: 'category',
      data,
      axisLabel: {
        formatter(value: number) {
          const format =
            dataType === DataType.HOUR ? 'MM-DD[\n]HH:mm' : dataType === DataType.DAY ? 'MM-DD' : 'YYYY-MM';
          return dayjs(value, 'x').format(format);
        },
      },
    },
    yAxis: [
      {
        type: 'value',
        name: 'kWh',
        nameTextStyle: {
          fontSize: 14,
          align: 'right',
        },
        nameGap: 30,
        splitLine: {
          lineStyle: {
            color: '#FFFFFF30',
          },
        },
      },
      {
        type: 'value',
        name: '',
        nameTextStyle: {
          fontSize: 14,
          align: 'left',
        },
        axisLine: {
          show: false,
        },
        nameGap: 30,
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: '{value}%',
        },
      },
    ],
    legend: {
      show: true,
      right: 50,
      top: 50,
      textStyle: {
        color: '#AFBCC4',
      },
      icon: 'rect',
      itemGap: 18,
      itemWidth: 16,
      itemHeight: 7,
    },
    series: [
      {
        type: 'bar',
        color: colors[0],
        name: '电量',
        yAxisIndex: 0,
        data: chartData[0]?.data.map((a, index) => {
          return [index, a.value];
        }),
      },
      {
        type: 'line',
        symbol: 'none',
        color: colors[1],
        name: dataType === DataType.HOUR ? '比上一小时' : dataType === DataType.DAY ? '比上日' : '比上月',
        yAxisIndex: 1,
        data: chartData[1]?.data.map((a, index) => {
          const n = chartData[0]?.data[index].value;
          if (a.value === 0) {
            return [index, null];
          }
          return [index, !isNil(n) && !isNil(a.value) ? ((n - a.value) / a.value) * 100 : null];
        }),
      },
      {
        type: 'line',
        symbol: 'none',
        color: colors[2],
        name:
          dataType === DataType.HOUR ? '比上一日同一小时' : dataType === DataType.DAY ? '比上一月同一日' : '比去年当月',
        yAxisIndex: 1,
        data: chartData[2]?.data.map((a, index) => {
          const n = chartData[0]?.data[index].value;
          if (a.value === 0) {
            return [index, null];
          }
          return [index, !isNil(n) && !isNil(a.value) ? ((n - a.value) / a.value) * 100 : null];
        }),
      },
    ],
  };
};

export default Analysis;
