import { Layout, Table as ATable } from 'antd';
import DragResize from '@/components/DragResize';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  FormTitle,
  Radio,
  Table,
  useBreadcrumbRoutes,
  Wrapper,
} from '@maxtropy/components';
import React, { useState } from 'react';
import styles from './index.module.scss';
import { getDataColumns } from './components/dataColumns';
import { ColumnsType } from 'antd/es/table';
import PvTree from './components/PvTree';
import dayjs, { Dayjs } from 'dayjs';
import { useRequest } from 'ahooks';
import { apiV2ReportListPost } from '@maxtropy/dmes-apis-v2';
import { v4 } from 'uuid';
import { DatePickerProps } from 'antd/es/date-picker';
import { getTableSummary } from '@/pages/PvIncomeReport/components/getTableSummary';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

const { Sider, Content } = Layout;

export enum ResolutionEnum {
  DAY = 'D',
  MONTH = 'M',
}

const PvIncomeReport = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [siderWidth, setSiderWidth] = useState(260);
  const [dragStatus, setDragStatus] = useState(false);
  const [openSider, setOpenSider] = useState(true);

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_pvId = urlSearchParams.get('pvId') || undefined;

  const [pvId, setPvId] = useState<number | undefined>(url_pvId ? Number(url_pvId) : undefined);
  const [resolution, setResolution] = useState<ResolutionEnum>(ResolutionEnum.DAY);
  const [from, setFrom] = useState<Dayjs>(dayjs().subtract(32, 'd'));
  const [to, setTo] = useState<Dayjs>(dayjs().subtract(1, 'd'));
  const [showIncome, setShowIncome] = useState(true);
  const [showTimePeriod, setShowTimePeriod] = useState(true);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    if (resolution === ResolutionEnum.DAY) {
      if (current.startOf('day') >= dayjs().startOf('day')) return true;
    } else {
      if (current > dayjs().endOf('day')) return true;
    }

    if (from) {
      if (resolution === ResolutionEnum.MONTH) {
        return Math.abs(current.startOf('month').diff(from.startOf('month'), 'months')) > 11;
      } else {
        return Math.abs(current.startOf('day').diff(from.startOf('day'), 'days')) > 31;
      }
    }
    return false;
  };

  const columns: ColumnsType<any> = getDataColumns(resolution, showIncome, showTimePeriod);

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2ReportListPost({
        pvId: pvId,
        resolution: resolution,
        from: resolution === ResolutionEnum.DAY ? from.startOf('day').valueOf() : from.startOf('month').valueOf(),
        to: resolution === ResolutionEnum.DAY ? to.endOf('day').valueOf() : to.endOf('month').valueOf(),
        showIncome: showIncome,
        showTimePeriod: showTimePeriod,
      });
      return res;
    },
    {
      ready: !!pvId,
      refreshDeps: [pvId, from, to, resolution, showIncome, showTimePeriod],
    }
  );

  const onExport = () => {
    window.open(
      '/api/v2/report/excel?' +
        qs.stringify(
          {
            pvId: pvId,
            resolution: resolution,
            from: resolution === ResolutionEnum.DAY ? from.startOf('day').valueOf() : from.startOf('month').valueOf(),
            to: resolution === ResolutionEnum.DAY ? to.endOf('day').valueOf() : to.endOf('month').valueOf(),
            showIncome: showIncome,
            showTimePeriod: showTimePeriod,
          },
          { indices: false }
        )
    );
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Sider
          theme="light"
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <PvTree
            defaultPvId={url_pvId ? Number(url_pvId) : undefined}
            onSelect={pvId => {
              setPvId(pvId);
            }}
          />
        </Sider>

        <Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>

          <div className={styles.contentInner}>
            <FormTitle title="电量收益统计" style={{ marginBottom: 16 }}></FormTitle>
            <div className={styles.topFilter}>
              <Radio.Group
                buttonStyle="solid"
                value={resolution}
                onChange={e => {
                  setResolution(e.target.value);
                  if (e.target.value === ResolutionEnum.DAY) {
                    setFrom(dayjs().subtract(32, 'd'));
                    setTo(dayjs().subtract(1, 'd'));
                  } else {
                    setFrom(dayjs().subtract(11, 'M'));
                    setTo(dayjs());
                  }
                }}
              >
                <Radio.Button value={ResolutionEnum.DAY}>按日</Radio.Button>
                <Radio.Button value={ResolutionEnum.MONTH}>按月</Radio.Button>
              </Radio.Group>
              <DatePicker.RangePicker
                allowClear={false}
                picker={resolution === ResolutionEnum.DAY ? 'date' : 'month'}
                value={[from, to]}
                onChange={dates => {
                  setFrom(dates[0]!);
                  setTo(dates[1]!);
                }}
                disabledDate={disabledDate}
              />
              <Checkbox
                checked={showIncome}
                onChange={e => {
                  setShowIncome(e.target.checked);
                }}
              >
                显示收益
              </Checkbox>
              <Checkbox
                checked={showTimePeriod}
                onChange={e => {
                  setShowTimePeriod(e.target.checked);
                }}
              >
                显示峰谷时段
              </Checkbox>
              <Button
                type="primary"
                wrapStyle={{
                  marginLeft: 'auto',
                }}
                onClick={onExport}
              >
                导出
              </Button>
            </div>

            <Table
              bordered
              rowKey={() => v4()}
              columns={columns}
              dataSource={data?.pvStationDataList}
              loading={loading}
              scroll={{
                x: 'max-content',
                y: 'calc(100vh - 410px)',
              }}
              summary={() => getTableSummary(data?.total, showIncome, showTimePeriod)}
            ></Table>
          </div>
        </Content>
      </Layout>
    </Wrapper>
  );
};

export default PvIncomeReport;
